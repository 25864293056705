import {useCallback} from 'react'
import {Product} from '../widgets/CategoryProductCardGrid/CategoryProductCard/CategoryProductCard.interface'
import {useGenericActions} from './useGenericActions'
import {useUpdateItemToCart} from 'src/hooks/updateCart/useUpdateItemToCart'

const useProductCardActions = ({source = ''}: {source: string}) => {
  const {genericAction} = useGenericActions()
  const {updateItemToCart} = useUpdateItemToCart()

  const handleCardClick = useCallback(
    (product: Product, shouldOpenMiniPdp = false, miniPdpCta = {}) => {
      const {action = '', actionData = {}} = product?.cta || {}
      if (shouldOpenMiniPdp) {
        genericAction({
          actionName: 'OPEN_MINI_PRODUCT_PAGE_BOTTOM_SHEET',
          params: {
            ...product,
            source,
            cta: {
              ...miniPdpCta,
              label: product?.ctaLabel,
              clickAction: {
                action: action,
                data: {...product, ...(actionData || {})},
              },
            },
          },
        })
      } else {
        genericAction({actionName: 'OPEN_PDP', params: {...product, source}})
      }
    },
    [genericAction, source]
  )

  const handleCtaClick = useCallback(
    (product: Product) => {
      const {action = '', actionData = {}} = product?.cta || {}
      if (!action) return
      genericAction({
        actionName: action,
        params: {...product, source: source, ...(actionData || {})},
      })
    },
    [genericAction, source]
  )
  const handleUpdateCartItem = useCallback(
    (id: number, sku: string, quantity: number) => {
      updateItemToCart({
        sku,
        quantity,
        isMiniCart: true,
      })
    },
    [updateItemToCart]
  )

  return {
    handleCardClick,
    handleCtaClick,
    handleUpdateCartItem,
  }
}

export default useProductCardActions
