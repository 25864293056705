import {usePost} from '@mosaic-wellness/core-utils'
import {useCallback, useState} from 'react'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {storage} from 'src/utils/browserStorage'

export const useRecentlyViewedProducts = () => {
  const {mutateAsync} = usePost<any, any, {urlKeys: any}>({
    url: API_END_POINTS.RECENTLY_VIEWED_PRODUCTS,
  })

  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([])

  const setUrlKeysToLocalStorage = useCallback(
    async (currentProductUrl: string, urlKeys: string[] = []) => {
      if (currentProductUrl) {
        let urlKeysToSaveInLocal = [currentProductUrl, ...urlKeys]
        urlKeysToSaveInLocal = urlKeysToSaveInLocal.slice(0, 8)
        storage.setItem(
          'recentlyViewedUrlKeys',
          JSON.stringify(urlKeysToSaveInLocal)
        )
      }
    },
    [storage]
  )

  const fetchRecentlyViewedProducts = useCallback(
    async (productUrlKey: string) => {
      try {
        const urlKeysFromLocalStorage: any = await storage.getItem(
          'recentlyViewedUrlKeys'
        )
        let urlKeysForFetchingData = []
        if (urlKeysFromLocalStorage)
          urlKeysForFetchingData = JSON.parse(urlKeysFromLocalStorage)

        if (Array.isArray(urlKeysForFetchingData)) {
          const urlKeysWithoutCurrentProduct = urlKeysForFetchingData.filter(
            (urlKey) => urlKey !== productUrlKey
          )

          if (urlKeysWithoutCurrentProduct.length > 0) {
            const response = await mutateAsync({
              urlKeys: urlKeysWithoutCurrentProduct,
            })
            const data = response.data || {}
            const {products = []} = data || {}
            setRecentlyViewedProducts(products)
          } else {
            setRecentlyViewedProducts([])
          }
          setUrlKeysToLocalStorage(productUrlKey, urlKeysWithoutCurrentProduct)
        } else {
          setRecentlyViewedProducts([])
          setUrlKeysToLocalStorage(productUrlKey, [])
        }
      } catch (error) {
        setRecentlyViewedProducts([])
        setUrlKeysToLocalStorage(productUrlKey, [])
      }
    },
    [storage, setUrlKeysToLocalStorage, mutateAsync]
  )

  return [
    {
      recentlyViewedProducts,
    },
    {
      fetchRecentlyViewedProducts,
    },
  ] as const
}
